import React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from "../components/layout"
import SEO from "../components/seo"

import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import "./groupPage.scss";

const nl2br = (str, is_xhtml) => {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    const breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2').replace(/&gt;/g, '>').replace(/&lt;/g, '<');
}

export default function submenuModulePage({ data }) {
    let group = null;
    if (data.gcms.submenuModules[0]) {
        group = data.gcms.submenuModules[0];
    }
    if (group) {
        let headerName = '';
        let metaTitle = '';
        let pageDescription = '';
        let metaDescription = '';
        let modules = [];
        if (group) {
            if (group.headerName) {
                headerName = group.headerName;
            }
            if (group.submenuPageTitle) {
                metaTitle = group.submenuPageTitle;
            }
            if (group.submenuPageDescription && group.submenuPageDescription.html) {
                pageDescription = nl2br(group.submenuPageDescription.html);
            }
            if (group.submenuPageMetaDescription) {
                metaDescription = group.submenuPageMetaDescription;
            }
            if (group.modules && group.modules.length > 0) {
                modules = group.modules;
            }
        }

        return (
            <Layout>
                <SEO title={headerName} description={metaDescription} urlParams={headerName.toLowerCase() + "-modules"} />
                <img className="print print-image" src='https://media.travelspirit.nl/original/sm/TravelSpirit_Logo-FC.png' alt="logo" loading="lazy" />
                <div className="modules-container">
                    <h1>{metaTitle}</h1>
                    <div className="row">
                        <div className="col-sm">
                            <div className="html-text" dangerouslySetInnerHTML={{ __html: nl2br(pageDescription) }} />
                        </div>
                    </div>

                    {modules.map(({ slug, ...module }) => {
                        return <div className="inline-html" key={slug}>
                            <h2 className="module-title">{module.descriptionTitle}</h2>
                            <div className="html-text" dangerouslySetInnerHTML={{ __html: nl2br(module.description.html) }} />
                            <Link className="module-link" key={slug} to={`/modules/${slug}`}>Meer over {module.moduleName}
                                <div className="icon baseline">
                                    <ArrowRightAltIcon />
                                </div>
                            </Link>
                        </div>
                    })}
                </div>
            </Layout>
        )
    } else {
        return (
            <Layout>
                <p>Error finding data</p>
            </Layout>
        )
    }
}

export const pageQuery = graphql`
query SubmenuModulesPageQuery($id: ID) {
    gcms {
        submenuModules(where: { id: $id }) {
            headerName
            submenuPageTitle
            submenuPageDescription { html }
            submenuPageMetaDescription
            modules {
                moduleName
                descriptionTitle
                description { html }
                slug
            }
        }
    }
}`;
